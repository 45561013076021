import { AuthService } from './AuthService';

const authService = new AuthService();
const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://gsr-dt-api-p001.azurewebsites.net';

// fetch 요청을 감싸는 커스텀 함수
async function streamFetch(url: string, options: RequestInit = {}): Promise<Response> {
    // console.log("streamFetch 호출");
    try {
        // 토큰 가져오기
        // console.log("토큰 가져오기");
        const token = await authService.getAccessToken();
        
        // 기본 헤더와 사용자 정의 헤더 병합
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            ...options.headers,
        };

        // 요청 내용 상세 로깅
        // console.log('Request URL:', `${BASE_URL}${url}`);
        // console.log('Request Headers:', JSON.stringify(headers, null, 2));
        if (options.body) {
            // console.log('Request Body:', typeof options.body === 'string' ? JSON.parse(options.body) : options.body);
        }

        // 요청 보내기
        const response = await fetch(`${BASE_URL}${url}`, {
            ...options,
            headers,
        });

        // 400 에러일 때 응답 내용 확인
        if (response.status === 400) {
            const errorData = await response.text();
            console.error('서버 응답 에러:', errorData);
            console.error('요청 본문:', options.body); // 에러 시 요청 본문도 함께 로깅
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData}`);
        }

        // 401 에러 처리 (토큰 만료)
        if (response.status === 401) {
            try {
                // 새 토큰 획득 시도
                // console.log("새 토큰 획득 시도");
                const newToken = await authService.getAccessToken();
                
                // 새 토큰으로 재요청
                // console.log("새 토큰으로 재요청");
                const retryResponse = await fetch(`${BASE_URL}${url}`, {
                    ...options,
                    headers: {
                        ...headers,
                        'Authorization': `Bearer ${newToken}`,
                    },
                });

                if (!retryResponse.ok) {
                    throw new Error(`HTTP error! status: ${retryResponse.status}`);
                }

                return retryResponse;
            } catch (e) {
                // 재인증 필요
                // console.log("재인증 필요");
                window.location.href = '/';
                throw new Error('Authentication failed');
            }
        }

        // 기타 에러 처리
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return response;
    } catch (error) {
        throw error;
    }
}

export const api = {
  async post(url: string, data: any, options: RequestInit = {}) {
      return streamFetch(url, {
          ...options,
          method: 'POST',
          body: JSON.stringify(data),
      });
  },
};