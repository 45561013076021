import { useRef, useState, useEffect } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import '../../index.css';

import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { getIndexList, getDocumentList, getDocument, updateContents } from '../../api/admin';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import MessagesPaneHeader from '../../components/MessagesPaneHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/AriStore';
import { documentType, initialDocument } from '../../types';
import Box from '@mui/material/Box/Box';
import Paper from '@mui/material/Paper/Paper';
import FormControl from '@mui/material/FormControl/FormControl';
import FormLabel from '@mui/material/FormLabel/FormLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Tabs from '@mui/material/Tabs/Tabs';
import Tab from '@mui/material/Tab/Tab';
import OutlinedInput from '@mui/material/OutlinedInput/OutlinedInput';
import Button from '@mui/material/Button/Button';

const callIndexList = async () => {
  const res = await getIndexList();
  return res.data;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ flexGrow: 1, overflow: 'auto' }}
    >
      {value === index && <Box sx={{ py: 2, px: 3, }}>{children}</Box>}
    </div>
  );
}

export default function IndexAdmin() {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [indexData, setIndexData] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [document, setDocument] = useState<documentType>(initialDocument);

  const [selectedLeftTab, setSelectedLeftTab] = useState<number>(0);
  const [selectedRightTab, setSelectedRightTab] = useState<number>(1);

  const textAreaRef = useRef<HTMLDivElement>(null);

  const menuInfo = useSelector((state: RootState) => state.menu);

  useEffect(() => {
    if (indexData.length === 0) {
      const index = callIndexList();
      index.then((resolvedIndex: any[]) => setIndexData(resolvedIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCategoryChange = async (event: SelectChangeEvent) => {
    const res = await getDocumentList(event.target.value);
    setCategory(res.data);
    setDocument(initialDocument);
  };

  const handleDocumentClick =async (event: SelectChangeEvent) => {
      const resDoc = await getDocument(event.target.value);
      setDocument({
        categoryCode: resDoc.data.categoryCode,
        id: resDoc.data.id,
        sourcepage: resDoc.data.sourcepage,
        sourcefile: resDoc.data.sourcefile,
        content: resDoc.data.content,
        bizTerms: resDoc.data.bizTerms,
        altTerms: resDoc.data.altTerms
      });
      setSelectedLeftTab(0);
      setSelectedRightTab(1); 
  };

  const handleUpdateMarkdown = async () => {
    const res = await updateContents(document);
    setDocument({
      categoryCode: res.data.categoryCode,
      id: res.data.id,
      sourcepage: res.data.sourcepage,
      sourcefile: res.data.sourcefile,
      content: res.data.content,
      bizTerms: res.data.bizTerms,
      altTerms: res.data.altTerms
    });
    res.status === 200 ? alert("Success") : alert("Fail");
  };

  // function a11yProps(index: number) {
  //   return {
  //     id: `simple-tab-${index}`,
  //     'aria-controls': `simple-tabpanel-${index}`,
  //   };
  // }

  // const [value, setValue] = useState(0);

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  return (
    <>
      <Sidebar />
      <Header />
      <Box component="main" className="MainContent" sx={{ flex: 1 }}>
        <Paper
          key={menuInfo.selectedIndex}
          sx={{
            height: { xs: '100dvh', lg: '100dvh' },
            display: 'flex',
            flexDirection: 'column',
            pt: { xs: 'var(--Header-height)', sm: 0 },
          }}
        >
          <>
            <MessagesPaneHeader menuTitle={menuInfo.selectedName} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', overflowY: 'auto', backgroundColor: '#F0F4F8', flexDirection: 'row', p: 1, gap: 1, minHeight: "50px", alignItems: 'center'  }}>
                <FormControl>
                  <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>대분류</FormLabel>
                  <Select sx={{ fontSize: "13px", minWidth: 200 }} defaultValue="ofc-handbook" >
                    {indexData.map((item: any) => (
                      <MenuItem key={item.indexCode} value={item.indexCode}>{item.indexName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <NavigateNextIcon sx={{ color: '#FF0000' }} />
                <FormControl>
                  <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>중분류</FormLabel>
                  <Select sx={{ fontSize: "13px", minWidth: 200 }} defaultValue="" onChange={handleCategoryChange}>
                    {indexData.map((item: any) => (
                      item.category.map((category: any) => (
                        <MenuItem key={category.categoryCode} value={category.categoryCode}>{category.categoryName}</MenuItem>
                      ))
                    ))}
                  </Select>
                </FormControl>
                <NavigateNextIcon  sx={{ color: '#FF0000' }} />
                <FormControl >
                  <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>문서</FormLabel>
                  <Select sx={{ fontSize: "13px", minWidth: 200 }} defaultValue="" onChange={handleDocumentClick}>
                    {category.map((doc, index) => (
                      <MenuItem key={index} value={doc.id}>{doc.sourcefile}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: "scroll" }}>
                {/* 좌측 패널 */}
                <Box sx={{
                  width: '750px', overflowY: 'auto', height: 960, fontSize: '12px',
                  margin: "16px 0px 0px 16px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "6px",
                  boxShadow: "rgb(11, 107, 203) 0px 0px 0px 0px inset",
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <Tabs aria-label="Basic tabs" value={selectedLeftTab} onChange={(event, newValue) => setSelectedLeftTab(newValue)} sx={{flexShrink: 0}}>
                      <Tab value={0} sx={{ fontSize: "13px" }} label="원문보기" />
                      <Tab value={1} sx={{ fontSize: "13px" }} label="Markdown" />
                  </Tabs>
                    <CustomTabPanel  value={selectedLeftTab} index={0}>
                      {document?.sourcefile !== undefined && document?.sourcefile !== "" ? (<Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                        <Viewer plugins={[defaultLayoutPluginInstance]} fileUrl={`https://gsrstorageaccountp001.blob.core.windows.net/content/${document?.sourcefile}`} />
                      </Worker>) : null}

                    </CustomTabPanel>
                    <CustomTabPanel value={selectedLeftTab} index={1}>
                      <ReactMarkdown
                        className="z-ui-markdown"
                        remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
                      >{document?.content}</ReactMarkdown>
                    </CustomTabPanel>
                </Box>

                {/* 우측 패널 */}
                <Box sx={{
                  width: '600px', overflowY: 'auto', height: 960,
                  margin: "16px 0px 0px 16px",
                  border: "1px solid #e0e0e0",
                  borderRadius: "6px",
                  boxShadow: "rgb(11, 107, 203) 0px 0px 0px 0px inset",
                  display: "flex",
                  flexDirection: "column",
                }}>
                  <Tabs aria-label="Basic tabs" value={selectedRightTab} onChange={(event, newValue) => setSelectedRightTab(newValue)} sx={{flexShrink: 0}}>
                    {/* <Tabs> */}
                      <Tab value={0} sx={{ fontSize: "13px" }} label="Markdown" />
                      <Tab value={1} sx={{ fontSize: "13px" }} label="편집" />
                    </Tabs>
                    <CustomTabPanel  value={selectedRightTab} index={0}>
                      <ReactMarkdown
                        className="z-ui-markdown"
                        remarkPlugins={[remarkMath, remarkGfm, remarkBreaks]}
                      >{document?.content}</ReactMarkdown>
                    </CustomTabPanel>
                    <CustomTabPanel value={selectedRightTab} index={1}>
                      <OutlinedInput
                        placeholder="Type something..."
                        aria-label="Message"
                        ref={textAreaRef}
                        // slotProps={{ textarea: { ref: textareaRef } }}
                        onChange={(e) => {
                          setDocument(prevDoc => {
                            if (!prevDoc) return prevDoc;
                            return {
                              ...prevDoc,
                              content: e.target.value,
                            };
                          })
                        }}
                        multiline
                        minRows={3}
                        maxRows={47}
                        sx={{ height: 835, fontSize: '12px', width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}
                        value={document?.content} />
                        <Button
                          aria-label="toggle password visibility"
                          onClick={handleUpdateMarkdown}
                          variant='contained'
                          color='error'
                          size='small'
                          sx={{ mt: 1, px: 5}}
                        >
                          업데이트
                        </Button>
                    </CustomTabPanel>
                  {/* </Tabs> */}
                </Box>
                {/* 용어 패널 */}
                <Box sx={{
                  width: '250px', overflowY: 'auto', height: "530px",
                  margin: "16px 0px 0px 16px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}>
                  <FormControl sx={{ marginTop: 1 }}>
                    <FormLabel sx={{ fontSize: "13px", fontWeight: 900 }}>표준 업무용어</FormLabel>
                    <OutlinedInput value={document?.bizTerms?.split(',').join('\n')} 
                      sx={{ fontSize: "13px" }} 
                      multiline
                      minRows={3}
                      maxRows={15}
                      onChange={(event) => {
                        setDocument(prevDoc => {
                          if (!prevDoc) return prevDoc;
                          return {
                            ...prevDoc,
                            bizTerms: event.target.value.split(',').join('\n'),
                          };
                        })
                      }}
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <IconButton
                      //       aria-label="toggle password visibility"
                      //       onClick={handleUpdateMarkdown}
                      //       edge="end"
                      //     >
                      //       <SendRoundedIcon color='primary'/>
                      //     </IconButton>
                      //   </InputAdornment>
                      // }
                      />
                  </FormControl>
                  <FormControl sx={{ py: 2 }}>
                    <FormLabel sx={{ fontSize: "13px", fontWeight: 700 }}>혼용어</FormLabel>
                    <OutlinedInput value={document?.altTerms?.split(',').join('\n')}
                      sx={{ fontSize: "13px" }}
                      multiline
                      minRows={3}
                      maxRows={15}
                      onChange={(event) => {
                      setDocument(prevDoc => {
                        if (!prevDoc) return prevDoc;
                        return {
                          ...prevDoc,
                          altTerms: event.target.value.split(',').join('\n'),
                        };
                      })
                    }}
                    // endAdornment={
                    //   <InputAdornment position="end">
                    //     <IconButton
                    //       aria-label="toggle password visibility"
                    //       onClick={handleUpdateMarkdown}
                    //       edge="end"
                    //     >
                    //       <SendRoundedIcon color='primary'/>
                    //     </IconButton>
                    //   </InputAdornment>
                    // }
                    />
                    {/* <Button
                          aria-label="toggle password visibility"
                          variant='contained'
                          color='warning'
                          size='small'
                          onClick={handleUpdateMarkdown}
                    >업데이트</Button> */}
                  </FormControl>
                  <FormControl sx={{ pt: 0, justifyContent: "flex-end" }}>
                    <Button variant='contained' color='primary' sx={{ width: "100%", alignSelf: 'flex-end', borderRadius: 'sm' }} onClick={handleUpdateMarkdown} >등록</Button>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </>
        </Paper>
      </Box>
    </>
  );
}