import {useRef, useEffect} from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

export type MessageInputProps = {
  textAreaValue: string;
  setTextAreaValue: (value: string) => void;
  onSubmit: () => void;
};

export default function MessageInput(props: MessageInputProps) {
  const { textAreaValue, setTextAreaValue, onSubmit } = props;
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const textAreaRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (textAreaValue.trim() !== '') {
      onSubmit();
      setTextAreaValue('');
    }
  };

  useEffect(() => {
      textareaRef.current?.focus();
  }, [])
  
  return (
    <Box sx={{ px: 2, pb: 3, bgcolor: '#F0F4F8' }}>
      <FormControl sx={{width: '100%'}}>
        <OutlinedInput
          placeholder="검색하지 말고 아리에게 질문해보세요!"
          minRows={2}
          maxRows={10}
          ref={textAreaRef}
          multiline
          sx={{bgcolor: 'white'}}
          onChange={(e) => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClick}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <SendRoundedIcon color='primary'/>
              </IconButton>
            </InputAdornment>
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.shiftKey || event.metaKey)) {
              event.preventDefault();
              const target = event.target as HTMLTextAreaElement;
              const { selectionStart, selectionEnd, value } = target;

              if (selectionStart !== null && selectionEnd !== null) {
                const newValue = value.slice(0, selectionStart) + '\n' + value.slice(selectionEnd);
                
                setTextAreaValue(newValue);
                setTimeout(() => {
                  if (target) {
                    target.selectionStart = target.selectionEnd = selectionStart + 1;
                  }
                }, 0);
              }
            } else if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
              event.preventDefault();
              handleClick();
            }
          }}
        />
      </FormControl>
    </Box>
  );
}