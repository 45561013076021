import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GlobalStyles, Avatar, Box, Divider, List, ListItem, ListItemButton, listItemButtonClasses, Typography, Paper, Dialog, DialogTitle } from "@mui/material";
import { RestaurantOutlined, MemoryOutlined, AssignmentRounded, AssignmentTurnedInOutlined, LocalPoliceOutlined, StorefrontOutlined, CalculateOutlined, GavelOutlined, LightModeOutlined, KitchenOutlined, Language, KeyboardArrowDown, KeyboardArrowRight, LockPersonOutlined } from '@mui/icons-material';
import { closeSidebar } from "../utils";
import { setMenu } from '../reducer/menu';
import { useMsal } from '@azure/msal-react';

const menuIcons: { [key: number]: JSX.Element } = {
  0: <AssignmentRounded color="success"/>,
  1: <StorefrontOutlined sx={{ color: "#636B74" }}/>,
  2: <CalculateOutlined sx={{ color: "#636B74" }}/>,
  3: <GavelOutlined sx={{ color: "#636B74" }}/>,
  4: <LightModeOutlined sx={{ color: "#636B74" }}/>,
  5: <KitchenOutlined sx={{ color: "#636B74" }}/>,
  6: <Language sx={{ color: "#636B74" }}/>,
  7: <RestaurantOutlined sx={{ color: "#636B74" }}/>,
  100: <LocalPoliceOutlined color="success"/>,
  200: <AssignmentTurnedInOutlined color="success"/>,
  500: <MemoryOutlined color="success"/>,
  510: <MemoryOutlined color="success"/>,
  900: <LockPersonOutlined color="success"/>,
  910: <LockPersonOutlined color="success"/>
}

const menuItems = [
  { index: 200, name: 'FAQ사례집', status: 'OPEN', welcome: "신임 OFC가 자주 묻는 질문부터 이슈 해결 노하우까지!\nFAQ사례집을 기반으로 알려드립니다."},
  { index: 100, name: '월간 영업/제도 안내', status: 'OPEN', welcome: "OFC Value Meeting에서 공유되는 월간 영업안내자료를 확인해보세요.\n\n**1월 4주차 경영주 필독 안내문**\n**OFC 페어제 우수페어 안내**\n**설 연휴 대응안**\n**설 명절 행사 안내**\n**설 명절 센터 운영 일정**\n**POS 개선 안내**\n**점포 위생등급제 신규 제도 운영**"},
  { index: 0, name: '이오보감', status: 'OPEN', welcome: "이오보감 매뉴얼에서 다양한 정보를 확인해보세요."}
];

export default function Sidebar() {
  const { accounts } = useMsal();

  const navigate = useNavigate();
  const selectedMenu = useSelector((state: any) => state.menu);
  const userInfo = useSelector((state: any) => state.user);

  const [topMenuopen, setTopMenuopen] = useState(true);
  const [alert, setAlert] = useState(false);

  const handleAlertClose = () => {
    setAlert(false);
  };
  
  if(userInfo.role === 'admin' && menuItems[menuItems.length - 1].index < 900){
    menuItems.push({ index: 500, name: 'AI 아리 (GPT)', status: 'OPEN', welcome: "GPT 최신버전 GPT-4o를 체험해 보라구" });
    menuItems.push({ index: 510, name: 'AI 아리 (Claude)', status: 'OPEN', welcome: "Claude 최신버전 Claude 3.5 Sonnet을 체험해 보라구" });
    menuItems.push({ index: 900, name: '인덱스 관리', status: 'OPEN', welcome: "" });
    menuItems.push({ index: 910, name: '문서 관리', status: 'OPEN', welcome: "" });
  }

  const handleClick = () => {
    setTopMenuopen(!topMenuopen);
  };

  const dispatch = useDispatch();

  const handleMenuClick = (index: number, name: string, status: string, welcome: string) => {
    if(status === "TBD"){
      setAlert(true);
    }else{
      dispatch(setMenu({index, name, welcome}));
    }
  };

  useEffect(() => {
    // if(selectedMenu.selectedIndex <= 100) navigate('/');
    // else if(selectedMenu.selectedIndex < 900) navigate('/gpt');
    if(selectedMenu.selectedIndex < 900) navigate('/');
    else if(selectedMenu.selectedIndex === 900) navigate('/console/index');
    else if(selectedMenu.selectedIndex === 910) navigate('/console/document');
  } , [navigate, selectedMenu]);


  return (
    <Paper
      className="Sidebar"
      sx={{ position: { xs: "fixed", sm: "sticky" },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          sm: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 10000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        // p: 2,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 9998,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          opacity: "var(--SideNavigation-slideIn)",
          backgroundColor: "var(--joy-palette-background-backdrop)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", p: 2 }}>
        <Avatar 
          // variant="outlined" 
          // size="lg"
          src="./logo.png" />
        <img src="./title-small.png" alt="logo" style={{ width: '145px', height: '35px' }} />
        {/* <Typography fontFamily="'Poetsen One', sans-serif" fontSize={35}>OFC GPT</Typography> */}
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
          px: 2
        }}
      >
        <List sx={{ "--List-nestedInsetStart": "30px" }} >
          {menuItems.map((item, index) => (
            <div key={index}>
              <ListItem key={index} sx={{flexDirection: 'flex-start', px: 0, py: 0.5}} /* secondaryAction={item.child ? (topMenuopen ? <KeyboardArrowDown /> : <KeyboardArrowRight />) : null} */>
                <ListItemButton
                  onClick={/* !item.child ? */ () => handleMenuClick(item.index, item.name, item.status, item.welcome) /* : handleClick */}
                  selected={item.index === selectedMenu.selectedIndex ? true : false}
                  
                  >
                  {menuIcons[item.index]}
                    <Typography 
                      sx={{
                        fontSize: 15, fontWeight: 600, color: item.status === "OPEN" ? "#000" : "#AEB4BA",
                        WebkitFontSmoothing: "antialiased"
                      }}>
                      {item.name}
                    </Typography>
                </ListItemButton>
              </ListItem>
                {/* {topMenuopen && item.child && (<List component="div">
                  {item.child.map((child, childIndex) => {
                    return (
                      <ListItem key={childIndex} sx={{flexDirection: 'flex-start', pl: 2, py: 0}}>
                        <ListItemButton onClick={() => handleMenuClick(child.index, child.name, child.status, child.welcome)}
                          selected={child.index === selectedMenu.selectedIndex ? true : false}>
                          {menuIcons[child.index]}
                            <Typography 
                              // level="title-sm"
                              sx={{
                                fontSize: 15, fontWeight: 600, color: child.status === "OPEN" ? "#000" : "#AEB4BA",
                                WebkitFontSmoothing: "antialiased"
                              }}>
                              {child.name}
                            </Typography>
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>)} */}
              </div>
          ))}
        </List>
      </Box>
      <Divider sx={{}}/>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", px: 2, py: 1 }}>
        <Avatar />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography sx={{fontSize: '0.9rem'}}>{accounts[0]['name']}</Typography>
        </Box>
      </Box>
      <Dialog
        open={alert}
        onClose={handleAlertClose}
      >
        <DialogTitle id="alert-dialog-title">
          {"메뉴 준비중입니다."}
        </DialogTitle>
      </Dialog>
    </Paper>
  );
}