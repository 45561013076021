import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuStateType {
  selectedIndex: number;
  selectedName: string;
  selectedWelcome: string;
}

const initialState: MenuStateType = {
  selectedIndex: 200,
  selectedName: "FAQ사례집",
  selectedWelcome: "신임 OFC가 자주 묻는 질문부터 이슈 해결 노하우까지!\nFAQ사례집을 기반으로 알려드립니다.",
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<{ index: number; name: string; welcome: string }>) => {
      state.selectedIndex = action.payload.index;
      state.selectedName = action.payload.name;
      state.selectedWelcome = action.payload.welcome;
    },
  },
});

export const { setMenu } = menuSlice.actions;
export default menuSlice.reducer;